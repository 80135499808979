<template>
	<div
		class="w-screen h-screen fixed top-0 left-0 z-50 flex flex-row justify-center
		items-center bg-gray-500 bg-opacity-50 overlay"
	>
		<div class="bg-white p-8 rounded">
			<h4 class="text-center text-xl mb-8">本当に退会していいですか？</h4>
			<div class="relative lg:mb-8 mb-12">
				<label class="block cursor-pointer mb-2">
					<input v-model="values.isDeleteAccount" :checked="!values.isDeleteAccount" :value="false" name="is_delete_account" type="radio" />
					絵を残します。現在登録済のメールアドレスは再度登録できません。
				</label>
				<label class="block cursor-pointer mb-2">
					<input v-model="values.isDeleteAccount" :checked="values.isDeleteAccount" :value="true" name="is_delete_account" type="radio" />
					絵を残しません。現在登録済のメールアドレスは再度登録できます。
				</label>
				<h4 class="text-base mb-4">パスワードを入力してください</h4>
				<input v-model="values.password" type="password" class="px-4 py-2 border border-gray-400 sm:w-96 h-8.5 w-full focus:outline-none rounded mb-2" />
				<div class="text-red-500 absolute text-12px">{{ errors.password }}</div>
			</div>
			<div class="flex flex-row justify-between lg:justify-end">
				<button class="px-4 py-2 bg-gray-200 focus:outline-none mr-8 rounded" @click="$emit('closeSettingModal')">
					キャンセル
				</button>
				<button class="px-4 py-2 bg-red-500 text-white focus:outline-none rounded" @click="settingSubscribleAction">
					退会する
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { useForm, useField } from 'vee-validate';
import { object, string, boolean } from 'yup';
import { ref } from 'vue';
import firebase from 'firebase/app';
import router from '@/router';
import userApi from '@/apis/userApi';
import { globalState, globalActions } from '@/stores/global';
import { f } from '@/services/utils';
import 'firebase/functions';

function useSettingSubscribleForm() {
	const isSubmitting = ref(false);
	const validationSchema = object({
		password: string()
			.required('現在のパスワードを入力してください。')
			.min(8, 'パスワードは半角英数字8〜20文字で入力してください。')
			.max(20, 'パスワードは半角英数字8〜20文字で入力してください。'),
		isDeleteAccount: boolean()
	});

	const form = useForm({ validationSchema });
	useField('password');
	useField('isDeleteAccount');

	const settingSubscribleAction = async () => {
		if (!(await form.validate()).valid || isSubmitting.value) return;
		try {
			isSubmitting.value = true;
			const user = firebase.auth().currentUser;
			const userId = f(globalState.user.id);
			// @ts-ignore
			const credential = firebase.auth.EmailAuthProvider.credential(user.email, form.values.password);
			// @ts-ignore
			await user.reauthenticateWithCredential(credential);
			if (form.values.isDeleteAccount) {
				await userApi.update(userId, {
					delete_flag: 1
				});
				await firebase.auth().signOut();
				await firebase
					.app()
					.functions('asia-northeast1')
					.httpsCallable('deleteUserByAdmin')(userId);
			} else {
				await userApi.update(userId, {
					user_deactive_flag: 1,
					name: '退会したユーザー',
					nickname: '退会したユーザー',
					icon_img: ''
				});
				await firebase.auth().signOut();
			}
			alert('ユーザーが退会しました。');
			globalActions.setUser({});
			router.push({ name: 'top' });
		} catch (error) {
			console.log(error);
			form.setErrors({
				password: 'パスワードが間違っています。'
			});
		} finally {
			isSubmitting.value = false;
		}
	};
	return {
		form,
		settingSubscribleAction,
		isSubmitting
	};
}
export default {
	name: 'SettingUnsubscribleUserModal',
	emits: ['closeSettingModal'],
	setup() {
		const { form, settingSubscribleAction, isSubmitting } = useSettingSubscribleForm();
		return {
			errors: form.errors,
			values: form.values,
			settingSubscribleAction,
			isSubmitting
		};
	}
};
</script>
