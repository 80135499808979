<template>
	<div class="flex-1">
		<div class="container" style="min-height: calc(100vh - 140px)">
			<label class="block lg:pt-20.8 lg:pb-18.75 lg:text-4xl text-2xl p-4 font-medium lg:pl-12.5">設定</label>
			<div class="lg:pl-12.5 lg:pr-10 lg:py-8.5 text-sm p-4 font-medium leading-4 border-t-2 border-gray-300">
				<p class="mb-3 font-semibold">パスワードを変更</p>
				<p>
					保護者の皆様へ <br /><br />
					こちらの設定より、パスワードの変更が可能です。<br /><br />
					設定されたパスワードは忘れぬよう、保護者の皆様が管理をお願い致します。
				</p>
				<div class="flex justify-end mt-3">
					<button
						type="button"
						class="flex flex-row items-center justify-center font-bold rounded-full border-3 border-asp-brown focus:outline-none w-167px h-41px mr-10px"
						@click="$router.push({ name: 'setting.change_password' })"
					>
						<img class="h-20px mr-10px" src="@/assets/images/pencil-black-icon.png" alt="edit icon" />
						<span class="font-bold">編集</span>
					</button>
				</div>
			</div>
			<div class="lg:pl-12.5 lg:pr-10 lg:py-8.5 text-sm p-4 font-medium leading-4 border-t-2 border-b-2 border-gray-300">
				<p class="mb-3 font-semibold">退会したい場合はこのボタンを押してください。</p>
				<div class="flex justify-end mt-3">
					<button
						type="button"
						class="flex flex-row items-center justify-center font-bold rounded-full border-3 border-asp-brown focus:outline-none w-167px h-41px mr-10px"
						@click="openSettingModal"
					>
						退会する
					</button>
				</div>
			</div>
			<div class="lg:pl-12.5 lg:pr-10 lg:py-8.5 text-sm p-4 font-medium leading-4 border-b-2 border-gray-300">
				<setting-notification></setting-notification>
			</div>
		</div>
		<setting-unsubscrible-user-modal v-if="state.showingSettingModal" @close-setting-modal="closeSettingModal"></setting-unsubscrible-user-modal>
	</div>
</template>
<script>
import { reactive } from 'vue';
import SettingUnsubscribleUserModal from './SettingUnsubscribleUser.vue';
import SettingNotification from './SettingNotification.vue';

export default {
	components: { SettingUnsubscribleUserModal, SettingNotification },
	setup() {
		const state = reactive({
			showingSettingModal: false
		});
		return {
			state,
			openSettingModal: () => {
				state.showingSettingModal = true;
			},
			closeSettingModal: () => {
				state.showingSettingModal = false;
			}
		};
	}
};
</script>
