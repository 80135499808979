<template>
	<div class="bg-white rounded">
		<div class="relative">
			<label class="block cursor-pointer mb-2">
				通知をもらいますか？
			</label>
			<div class="text-right">
				<input
					id="notification_toggle_btn"
					v-model="onNotification"
					className="tooglebtn--cmnToggle tooglebtn--cmnToggleRoundFlat large"
					type="checkbox"
					:checked="onNotification"
					:disabled="isSubmitting"
					@change="settingNotificationAction"
				/>
				<label htmlFor="notification_toggle_btn" />
				<span className="tooglebtn--title">{{ onNotification ? 'はい' : 'いいえ' }}</span>
			</div>
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/app';
import userApi from '@/apis/userApi';
import { globalState } from '@/stores/global';
import { f } from '@/services/utils';
import 'firebase/functions';

export default {
	name: 'SettingUnsubscribleUserModal',
	emits: ['closeSettingModal'],
	setup() {},
	data() {
		return {
			onNotification: false,
			isSubmitting: false
		};
	},
	mounted() {
		this.handleFetchUser();
	},
	methods: {
		handleFetchUser() {
			firebase
				.firestore()
				.collection('d_user')
				.doc(f(globalState.user.id))
				.get()
				.then(documentSnapshot => {
					this.onNotification = !documentSnapshot.data()?.off_notification;
				})
				.catch(error => {
					console.log(error);
				});
		},
		async settingNotificationAction() {
			if (this.isSubmitting) return;
			await new Promise(resolve => setTimeout(() => resolve(null), 400));
			try {
				this.isSubmitting = true;
				const userId = f(globalState.user.id);
				await userApi.update(userId, {
					off_notification: !this.onNotification
				});
				alert('設定を更新しました');
			} catch (error) {
				console.log(error);
				alert('設定を更新できません。');
			} finally {
				this.isSubmitting = false;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.tooglebtn {
	overflow: hidden;
	padding: 10px 0 2px;
	&--title {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
	}
	&--cmnToggle {
		position: absolute;
		margin-left: -9999px;
		visibility: hidden;
	}
	&--cmnToggle + label {
		display: inline-block;
		position: relative;
		cursor: pointer;
		outline: none;
		user-select: none;
		margin-top: 2px;
	}
	&--cmnToggleRoundFlat + label {
		padding: 2px;
		width: 35px;
		height: 17px;
		background-color: #ccc;
		border-radius: 8px;
		transition: background 0.4s;
		margin-right: 10px;
		vertical-align: middle;
		&:before,
		&:after {
			display: block;
			position: absolute;
			content: '';
		}
		&:before {
			top: 2px;
			left: 2px;
			bottom: 2px;
			right: 2px;
			background-color: #ccc;
			border-radius: 8px;
			transition: background 0.4s;
		}
		&:after {
			top: 0;
			left: 0;
			bottom: 0;
			width: 17px;
			background-color: #fff;
			border: 1px solid #ccc;
			border-radius: 8px;
			transition: margin 0.4s, background 0.4s;
		}
	}
	&--cmnToggleRoundFlat:checked + label {
		background-color: #765d3a;
		&:before {
			background-color: #765d3a;
		}
		&:after {
			margin-left: 17px;
			background-color: #fff;
			border-color: #765d3a;
			top: 0;
			left: 0;
			bottom: 0;
			width: 17px;
		}
	}

	&--cmnToggle.large + label {
		width: 72px;
		height: 36px;
		border-radius: 18px;
		&::before {
			border-radius: 18px;
		}
		&::after {
			border-radius: 18px;
			width: 36px;
		}
	}
	&--cmnToggle.large:checked + label::after {
		margin-left: 36px;
	}
	&--cmnToggle.large ~ .tooglebtn--title {
		font-size: inherit;
	}
}
</style>
